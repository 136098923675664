import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w09KG01Schnuppern = () => (
  <Layout>
    <SEO title="w09KG01Schnuppern" />

    <h1 id="ueberschrift">Kreatives Gestalten</h1>
    <h2>Schnuppertreff für Interessierte &emsp;  ©&nbsp;1993</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w09KG01Schnuppern&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        "Glück liegt in der Freude des Erreichten und
        im Erlebnis der kreativen Bemühungen."
        <div style={{fontSize: "0.75em"}}>Franklin Delano Roosevelt (1882 - 1945)</div>
      </p>

    <p>---</p>

      <p>
        Von Armstulpen aus Wolle bis zum Zyklopen aus Knetmasse:<br />
        Alles macht viel mehr Spaß, wenn es zusammen mit anderen erforscht und praktiziert werden kann.
        In geselliger Runde können Sie Ihr individuelles kreatives Talent fördern und auch einmal in andere Themengebiete
        hineinschnuppern. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen
        oder einfach bei lustigen und interessanten Anekdoten die Zeit mit kreativem Gestalten genießen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: + je nach individuellem Wunsch +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und etwas Freiraum
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig + <br/>
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w09KG01Schnuppern
